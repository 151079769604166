import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AuthProvider } from "./Auth/provider";
import Auth from "./Auth/login";
import App from "./App";
import Register from "./Auth/register";
import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./Home";
import { ProtectedRoute } from "./Auth/routeguard";
import Payment from "./Payment";
import { Contact } from "./Legal/Contact";
import { Chat } from "./Beratung/chat";
import { Contract } from "./Contract/contract";
import { Terms } from "./Legal/Terms";
import { Privacy } from "./Legal/Privacy";
import ScrollToTop from "./scrollToTop";
import { AboutUs } from "./Legal/AboutUs";
import { ConfirmEmail } from "./Account/confim-email";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HashRouter>
      <ScrollToTop />
      <AuthProvider>
        <Routes>
          {/*<Redirect exact from="/" to="/index" />*/}
          {/*<Route path="/authenticate" component={Authenticate} />*/}
          <Route path="/contract" element={<Contract />} />
          <Route path="/beratung" element={<Chat />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/app"
            element={
              <ProtectedRoute>
                <App />
              </ProtectedRoute>
            }
          />
          <Route path="/payment" element={<Payment />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/register" element={<Register />} />

          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/:email/confirm-email" element={<ConfirmEmail />} />
        </Routes>
      </AuthProvider>
    </HashRouter>
  </React.StrictMode>
);

//reportWebVitals();
