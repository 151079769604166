import { useState } from "react";
import {
  Input,
  Button,
  SplitPage,
  Container,
  HyperLink,
} from "../shared/components.js";
import { AuthError, HomeButton } from "./components.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./provider.jsx";

const Auth = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [readyForSignIn, setReadyForSignIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authState, setAuthError] = useState(false);
  const [authError, setError] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const continueClicked = () => {
    if (!email) {
      setAuthError(true);
      setError("emailMissing");
      return;
    }

    if (!emailRegex.test(email)) {
      setAuthError(true);
      setError("invalidEmail");
      return;
    }

    setAuthError(false);
    setItems((prevItems) => [...prevItems, `Item ${prevItems.length + 1}`]);
    setReadyForSignIn(true);
  };

  const handleSignIn = async () => {
    switch (true) {
      case !email.trim():
        setAuthError(true);
        setError("emailMissing");
        return;
      case !password.trim():
        setAuthError(true);
        setError("passwordMissing");
        return;
      case !email.trim() && !password.trim():
        setAuthError(true);
        setError("inputMissing");
        return;
    }

    setAuthError(false);

    try {
      const authResult = await signIn(email, password);
      if (authResult.redirect) {
        navigate(authResult.redirect);
      }
    } catch (error) {
      setAuthError(error.authError);
      setError(error.type);
    }
  };

  return (
    <div>
      <SplitPage color="white" float="left">
        <HomeButton />
        <Container
          style={{ width: "30vw" }}
          itemsPerRow={1}
          itemWidth="30vw"
          margin="10rem 0rem 50rem 10rem"
        >
          <h1>Login</h1>
          <h2>Was ist Ihre Email?</h2>
          <Input
            onChange={handleEmailChange}
            placeholder="Geben Sie Ihre Email ein."
            type="email"
          ></Input>
          {items.map((item, index) => (
            <Input
              onChange={handlePasswordChange}
              type="password"
              placeholder="Passwort eingeben"
            ></Input>
          ))}
          <Button
            onClick={readyForSignIn ? handleSignIn : continueClicked}
            label={readyForSignIn ? "Einloggen" : "Weiter"}
          ></Button>
          <AuthError visibility={authState} type={authError}></AuthError>
          {/*<h1 style={{ textAlign: "center" }}>Social Login</h1>*/}
          <HyperLink href="/register">Oder hier registrieren</HyperLink>
        </Container>
      </SplitPage>
      <SplitPage float="right">
        <Container
          itemsPerRow={1}
          itemWidth="80%"
          style={{ margin: "5rem 0rem 0rem 5rem" }}
        >
          <h3 style={{ color: "white", fontSize: "2rem" }}>
            So bekommen Sie <span style={{ color: "black" }}>Recht </span> in
            vier Schritten.
          </h3>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              1
            </h3>
            <p style={{ justifyContent: "center" }}>
              Erkundigen Sie sich unverbindlich über Ihre Möglichkeiten.
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              2
            </h3>
            <p style={{ justifyContent: "center" }}>
              Erhalten Sie schnell und rund um die Uhr Hilfe bei Ihrem Anliegen.
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              3
            </h3>
            <p style={{ justifyContent: "center" }}>
              Wir verbinden Sie mit einer spezialisierten Kanzlei aus unserem
              Netzwerk.
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              4
            </h3>
            <p style={{ justifyContent: "center" }}>
              Erhalten Sie Recht in Ihrer Sache.
            </p>
          </Container>
        </Container>
      </SplitPage>
    </div>
  );
};
export default Auth;
