import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";

export const Partners = () => {
  const backgroundStyle = {
    backgroundColor: "rgba(105, 173, 255, .5)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "5%",
  };

  const titleStyles = {
    fontSize: "3rem",
    fontWeight: 900,
    width: "100%",
    lineHeight: "3.2rem",
    marginLeft: "5%",
  };

  const subtitleStyles = {
    fontSize: "1.3rem",
    width: "80%",
    marginLeft: "5%",
  };

  const buttonStyles = {
    backgroundColor: "white",
    width: "15rem",
    height: "3rem",
    fontSize: "1.1rem",
    borderRadius: "100px",
    marginTop: "5%",
    marginLeft: "5%",
    border: "none",
  };

  const customClickHandler = () => {
    window.scrollTo(0, 5500);
  };

  useAnalytics();

  return (
    <div style={backgroundStyle}>
      <Container
        itemsPerRow={2}
        margin="0"
        columnWidths={["45%", "55%"]}
        itemWidth="100%"
      >
        <img
          style={{ left: 0, bottom: 0, top: 0 }}
          width={"100%"}
          src={require("../assets/lawyer.png")}
        ></img>
        <div>
          <p style={titleStyles}>EIN TEAM, RENOMMIERTE PARTNER KANZLEIEN</p>
          <p style={subtitleStyles}>
            Nach der kostenlosen Erstberatung empfehlen wir Ihnen eine
            renommierte Kanzlei, spezialisiert auf Ihr Problem, wenn rechtliche
            Maßnahmen sinnvoll sind.
          </p>
          <button onClick={() => customClickHandler()} style={buttonStyles}>
            Loslegen
          </button>
        </div>
      </Container>
    </div>
  );
};
