import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "./components";

export const Header = ({
  onAccountClick,
  parentRef,
  nestedRef,
  staticColor = false,
}) => {
  const [bgColor, setBgColor] = useState("");

  const color = staticColor
    ? "black"
    : bgColor === "rgba(0, 0, 0, 0)"
      ? "black"
      : "white";

  let newColor;
  let parentColor;
  useEffect(() => {
    if (!parentRef || !parentRef.current) return;
    const handleScroll = () => {
      if (parentRef.current && nestedRef.current) {
        parentColor = window.getComputedStyle(
          parentRef.current
        ).backgroundColor;
        newColor = window.getComputedStyle(nestedRef.current).backgroundColor;

        const nestedRefRect = nestedRef.current.getBoundingClientRect();

        const isNestedRefVisible =
          nestedRefRect.top < window.innerHeight && nestedRefRect.bottom > 0;
        if (!isNestedRefVisible) {
          setBgColor(parentColor);
        } else {
          setBgColor(newColor);
        }
        console.log(bgColor);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [parentRef, nestedRef]);

  const headerStyles = {
    position: "fixed",
    backgroundColor: "transparent",
    width: "100%",
    zIndex: 10,
    top: 0,
  };

  return (
    <header style={headerStyles}>
      <Container
        itemsPerRow={3}
        columnWidths={["25%", "50%", "25%"]}
        itemWidth="100%"
        style={{ width: "100%" }}
      >
        <LogoButton url="/" color={color} />
        <Container
          itemsPerRow={5}
          itemWidth="fit-content"
          style={{ width: "100%", display: "flex", marginTop: "2.5%" }}
        >
          <HeaderButton color={color}>Vertrag</HeaderButton>
          <HeaderButton color={color}>Beratung</HeaderButton>
          <HeaderButton color={color}>Prozesse</HeaderButton>
          <HeaderButton color={color}>Partner Kanzleien</HeaderButton>
          <AccountButton color={color} onClick={onAccountClick}>
            Konto
          </AccountButton>
        </Container>
        {false ? (
          <SignUpButton color={color} url="/register">
            Anmelden
          </SignUpButton>
        ) : undefined}
      </Container>
    </header>
  );
};

const HeaderButton = ({ url, children, color }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url); // Redirect to the specified URL
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        border: "none",
        appearance: "none",
        backgroundColor: "transparent",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
        whiteSpace: "nowrap",
        color,
      }}
    >
      {children}
    </button>
  );
};

const LogoButton = ({ url, color }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url); // Redirect to the specified URL
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        border: "none",
        appearance: "none",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontSize: "2rem",
        marginLeft: "50%",
        marginTop: "3%",
        cursor: "pointer",
        whiteSpace: "nowrap",
        color,
      }}
    >
      smartmieter
    </button>
  );
};

const SignUpButton = ({ url, children, color }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url); // Redirect to the specified URL
  };

  const computedStyles = {
    white: { color: "black", backgroundColor: "white" },
    black: { color: "white", backgroundColor: "#69ADFF" },
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        width: "12rem",
        padding: "10px 20px",
        fontSize: "1.3rem",
        backgroundColor: "69ADFF",
        border: "none",
        color: "white",
        fontWeight: 600,
        borderRadius: "25px",
        cursor: "pointer",
        marginTop: "3%",
        marginLeft: "20%",
        ...computedStyles[color],
      }}
    >
      {children}
    </button>
  );
};

const AccountButton = ({ url, children, onClick, color }) => {
  return (
    <button
      onClick={onClick}
      style={{
        padding: "10px 20px",
        fontSize: "16px",
        border: "none",
        backgroundColor: "transparent",
        color,
      }}
    >
      {children}
    </button>
  );
};
