import { useAuth } from "./provider";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user || !user.accountStatus === "inactive") {
      navigate("/login");
    }
  }, [user, navigate]);

  return user ? children : null;
};
