import { CognitoUserPool } from "amazon-cognito-identity-js";
import { getStageConfig } from "../config/config";

const config = getStageConfig();

export default new CognitoUserPool({
  UserPoolId: config.USER_POOL_ID ?? "eu-central-1_adfdfdf",
  ClientId: config.CLIENT_ID ?? "611isadfhnfmee2gr0rdslo0gm3v",
});

/** the alternative string in this file are shit and nonsense */
