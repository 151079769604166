import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";

export const How = () => {
  useAnalytics();

  return (
    <div style={{ marginTop: "10%", height: "160vh" }}>
      <Container
        margin="0"
        itemWidth="fit-content"
        itemsPerRow={1}
        columnWidths={["100%"]}
        style={{ marginLeft: "5%", width: "100%" }}
      >
        <p
          style={{
            fontWeight: 800,
            fontSize: "3rem",
            whiteSpace: "nowrap",
            marginBottom: 0,
          }}
        >
          SO EINFACH IST SMARTMIETER
        </p>
        <p
          style={{ fontSize: "1.2rem", fontWeight: 500, width: "50%", top: 0 }}
        >
          Lassen Sie sich bei all Ihren Problemen rund um Miete helfen. Wir
          unterstützen Sie rund um die Uhr mit professioneller Hilfe und
          verbinden Sie bei rechtlichem Anspruch mit unseren Partnerkanzleien.
        </p>
        <Container
          margin="0"
          itemWidth="100%"
          itemsPerRow={2}
          columnWidths={["50%", "50%"]}
          style={{ marginTop: "5%", width: "100%" }}
        >
          <Container
            margin="0"
            itemWidth="100%"
            itemsPerRow={1}
            style={{ top: 0, height: "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require("../assets/reasons.png")}
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                width: "80%",
                marginTop: "2%",
                top: 0,
                marginBottom: 0,
              }}
            >
              Sie haben kaltes Wasser, zahlst zu viel Miete oder hast eine hohe
              Nebenkostenabrechnung <br></br> oder eine ungültige Klausel im
              Mietvertrag?
            </p>
            <p style={{ fontWeight: 500, fontSize: "1.2rem", width: "80%" }}>
              Wenn Sie unzufrieden mit Ihrer Wohnung sind oder durch Verträge
              oder Rechnungen belastet werden helfen wir Ihnen dabei. Wir
              zeigen, was rechtlich erlaubt ist und wogegen wir für Sie vorgehen
              können.
            </p>
          </Container>
          <Container
            margin="0"
            itemWidth="100%"
            style={{ top: 0, height: "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require("../assets/chat-explanation.png")}
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                marginTop: "2%",
                width: "80%",
                marginBottom: 0,
              }}
            >
              Erhalten Sie rund um die Uhr kostenlose Erstberatung und erfahre
              mehr über Ihre Möglichkeiten.
            </p>
            <p style={{ fontWeight: 500, fontSize: "1.2rem", width: "80%" }}>
              Starten Sie jetzt Fragen über Ihre Situation zu stellen und wir
              prüfen für Sie den rechtlichen Anspruch. Bei smartmieter gibt es
              keine langen Wartezeiten, hohe oder intransparente Kosten. Sie
              bekommen genau dann die Hilfe, wann Sie gebraucht wird und
              innerhalb kürzester Zeit - Ihre Probleme, Ihre Bedingungen.
            </p>
          </Container>
        </Container>

        <Container
          margin="0"
          itemWidth="100%"
          itemsPerRow={2}
          columnWidths={["50%", "50%"]}
          style={{ marginTop: "20%", width: "100%" }}
        >
          <Container
            margin="0"
            itemWidth="100%"
            itemsPerRow={1}
            style={{ top: 0, height: "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require("../assets/mediation.png")}
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                width: "80%",
                marginTop: "2%",
                marginBottom: 0,
              }}
            >
              Wir lösen Ihr Problem für Sie.
            </p>
            <p style={{ fontWeight: 500, fontSize: "1.2rem", width: "80%" }}>
              Nachdem wir Ihr Problem verstanden haben, gehen wir für Sie auf
              die Hausverwaltung oder Ihren Vermieter zu um im ersten Schritt
              eine angenehme Lösung zu finden. Sollte das nicht funktionieren
              übernimmt unser Experten Team die weiteren rechtlichen Schritte.
            </p>
          </Container>
          <Container
            margin="0"
            itemWidth="100%"
            style={{ top: 0, height: "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require("../assets/done-job.png")}
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                marginTop: "2%",
                width: "80%",
                marginBottom: 0,
              }}
            >
              Sie erhalten das Recht, was Ihnen zusteht.
            </p>
            <p style={{ fontWeight: 500, fontSize: "1.2rem", width: "80%" }}>
              Wenn Sie im Recht sind, sollte es keine Frage von Geld oder Zeit
              sein, dass Sie es bekommen. Mit smartmieter sorgen wir dafür, dass
              jeder sein Recht bekommt und unterstützen im gesamten Prozess. Ob
              bei Mietminderungen, finanziellen Entschädigungen,
              Vertragsproblemen oder um Ihr komfortables Wohnerlebnis zu
              sichern.
            </p>
          </Container>
        </Container>
      </Container>
    </div>
  );
};
