import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { forwardRef } from "react";

export const HeroSection = forwardRef(
  ({ title1, title2, text1, text2, url }, ref) => {
    const styles = {
      backgroundColor: "#69ADFF",
      height: "100vh",
      width: "100%",
      marginBottom: "5%",
      top: 0,
    };

    const heroTitleStyle = {
      fontSize: "5rem",
      color: "white",
      fontWeight: 800,
      lineHeight: "4.9rem",
      marginTop: "30%",
      marginLeft: "5%",
    };

    const subtitleStyle = {
      color: "white",
      fontSize: "1.5rem",
      marginLeft: "5%",
      marginTop: "1%",
      maxWidth: "70%",
    };

    const customClickHandler = () => {
      window.scrollTo(0, 5500);
    };

    useAnalytics();

    return (
      <section style={styles} ref={ref}>
        <Container
          itemsPerRow={2}
          itemWidth="100%"
          style={{ width: "90%" }}
          columnWidths={["70%", "30%"]}
        >
          <div>
            <h1 style={heroTitleStyle}>
              {title1} <br></br> {title2}
            </h1>
            <h2 style={subtitleStyle}>
              {text1} <br></br> {text2}
            </h2>
            <button
              onClick={() => customClickHandler()}
              style={{
                backgroundColor: "white",
                width: "15rem",
                borderRadius: "100px",
                textAlign: "center",
                height: "3rem",
                lineHeight: "3rem",
                fontSize: "1.3rem",
                fontWeight: 600,
                border: "none",
                marginTop: "3%",
                marginLeft: "5%",
              }}
            >
              Starten
            </button>
          </div>
          <img style={{ width: "100%", marginTop: "15%" }} src={url}></img>
        </Container>
      </section>
    );
  }
);
