import { useState } from "react";
import { Container } from "../../shared/components";
import axios from "axios";
import { useAnalytics } from "../../analytics";
import { getStageConfig } from "../../config/config";

export const WaitList = () => {
  const config = getStageConfig();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  useAnalytics();

  const addToWaitlist = async () => {
    let result;
    try {
      result = await axios.post(`${config.API_URL}/default/waitlist/${email}`);
    } catch (error) {
      console.error(error);
    }

    if (result && result.status === 201) {
      setStatus("Wir haben Sie erfolgreich der Warteliste hinzugefügt.");
    } else {
      setStatus("Ein Problem ist aufgetreten");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        width: "100%",
        height: "40rem",
        marginTop: "20%",
      }}
    >
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <p
          style={{
            fontWeight: 800,
            width: "80%",
            marginLeft: "10%",
            fontSize: "2.5rem",
            color: "white",
            textAlign: "center",
            marginTop: "10%",
            marginBottom: "0",
          }}
        >
          SICHEREN SIE SICH GEGEN ALL IHRE MIETPROBLEME AB UND SPAREN SIE HOHE
          ANWALTSKOSTEN
        </p>
        <p
          style={{
            fontWeight: 700,
            width: "80%",
            marginLeft: "10%",
            fontSize: "1.5rem",
            color: "lightgray",
            textAlign: "center",
            marginTop: "0",
          }}
        >
          Wir benachrichtigen Sie wenn wir soweit sind.
        </p>
        <Container
          itemsPerRow={1}
          columnWidths={["100%"]}
          itemWidth="auto"
          style={{
            marginTop: "4%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
            <input
              style={{
                borderRadius: "10px",
                width: "30rem",
                height: "3.5rem",
                backgroundColor: "lightgray",
                color: "black",
                fontSize: "1.1rem",
              }}
              type="email"
              placeholder="Ihre Email Addresse"
              onChange={(event) => setEmail(event.target.value)}
            ></input>
            <button
              onClick={() => addToWaitlist()}
              style={{
                borderRadius: "10px",
                width: "30rem",
                height: "3.5rem",
                backgroundColor: "white",
                color: "black",
                border: "none",
                fontSize: "1.1rem",
                marginTop: "1.5rem",
                marginLeft: "0",
              }}
            >
              Anmelden
            </button>
            <p
              style={{
                color: "white",
                textAlign: "center",
                fontSize: "0.9rem",
              }}
            >
              {status}
            </p>
          </div>
        </Container>
      </Container>
    </div>
  );
};
