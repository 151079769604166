import React, { useState } from "react";
export const Explainer = ({ title1, instructions }) => {
  return (
    <div
      style={{
        borderRadius: "8px",
        width: "20%",
        marginLeft: "1%",
        justifyContent: "center",
        backgroundColor: "#69ADFF",
      }}
    >
      <h1 style={{ paddingLeft: "20px", paddingRight: "20px", color: "white" }}>
        {title1}
      </h1>
      {instructions.map((text, index) => (
        <div key={index}>
          <h2
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              color: "white",
            }}
          >
            Schritt {index + 1}:
          </h2>
          <h2
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              color: "white",
            }}
          >
            {text}
          </h2>
        </div>
      ))}
    </div>
  );
};
