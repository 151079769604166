import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";

export const ServiceCharge = () => {
  const containerStyle = {
    width: "85%",
    height: "35rem",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#AAD0FF",
    borderRadius: "1rem",
  };

  const buttonStyle = {
    width: "16rem",
    height: "3.5rem",
    backgroundColor: "black",
    color: "white",
    border: "none",
    borderRadius: "100px",
    marginTop: "10%",
    fontSize: "1rem",
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "80% 20%",
  };

  const customClickHandler = () => {
    window.scrollTo(0, 5500);
  };

  useAnalytics();

  return (
    <div style={{ height: "40rem", width: "100%", marginTop: "10%" }}>
      <div style={containerStyle}>
        <Container
          itemsPerRow={2}
          itemWidth="auto"
          columnWidths={["60%", "40%"]}
        >
          <div
            style={{
              marginTop: "5%",
              marginLeft: "5%",
              fontSize: "1.5rem",
              width: "100%",
            }}
          >
            <h2 style={{ marginTop: "20%", fontWeight: 800 }}>
              HOHE NEBENKOSTENABRECHNUNG? <br></br>TUN SIE ETWAS DAGEGEN.
            </h2>
            <h3 style={{ fontSize: "1.2rem", fontWeight: 500, width: "80%" }}>
              Im Durchschnitt sind Nebenkostenabrechnungen um 50-200€ falsch
              berechnet. Prüfen Sie mit smartmieter Ihre Nebenkostenabrechnung
              und erhalten Sie Überzahlungen in Minuten zurück.
            </h3>
            <button style={buttonStyle} onClick={() => customClickHandler()}>
              Abrechnung prüfen
              <img
                style={{ width: "50%" }}
                src={require("../../shared/assets/continue-arrow.png")}
              ></img>
            </button>
          </div>
          <img
            style={{
              width: "100%",
            }}
            src={require("../assets/bill.png")}
          ></img>
        </Container>
      </div>
    </div>
  );
};
