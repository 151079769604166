import React from "react";
import { Footer } from "../shared/footer";
import { Header } from "../shared/header";
import { Features } from "./components/features";
import { EasyHelp } from "./components/easyhelp";
import { Partners } from "./components/partners";
import { How } from "./components/how";
import { Plans } from "./components/plans";
import { FAQ } from "./components/faq";
import { WaitList } from "./components/waitlist";
import { HeroSection } from "./components/hero";
import { useRef } from "react";
import { ServiceCharge } from "./components/servicecharge";
import { useAnalytics } from "../analytics";
import ReactGA from "react-ga4";

ReactGA.initialize("G-7ZVT49R3P1");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

const Home = () => {
  const parentRef = useRef();
  const nestedRef = useRef(null);

  useAnalytics();

  return (
    <div
      ref={parentRef}
      style={{
        padding: 0,
        bottom: 0,
        margin: 0,
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header parentRef={parentRef} nestedRef={nestedRef}></Header>
      <HeroSection
        title1="HILFE BEI ALLEM"
        title2="RUND UM IHRE MIETE"
        text1="Sie sind unglücklich mit Ihrer aktuellen Miete?"
        text2="Erfahre, welche Möglichkeiten Sie haben etwas dagegen zu unternehmen."
        url={require("./assets/hero-reasons.png")}
        ref={nestedRef}
      />
      <Features></Features>
      <ServiceCharge></ServiceCharge>
      <EasyHelp></EasyHelp>
      <Partners></Partners>
      <How></How>
      <WaitList></WaitList>
      {/*<Plans></Plans>*/}
      {/*<FAQ></FAQ>*/}
      <Footer></Footer>
    </div>
  );
};

export default Home;
