import axios from "axios";
import { useEffect } from "react";
import { getStageConfig } from "../config/config";
import { HomeButton } from "../Auth/components";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const ConfirmEmail = () => {
  const config = getStageConfig();

  const { email } = useParams();

  const [status, setStatus] = useState("");

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const result = await axios.post(
          `${config.API_URL}/default/waitlist/${email}/confirm`
        );

        if (result.status === 200) {
          setStatus("success");
        } else {
          setStatus("error");
        }
      } catch (error) {
        console.error(error);
      }
    };

    confirmEmail();
  });

  return (
    <div>
      <HomeButton />
      {status === "success" ? (
        <div style={{ marginLeft: "5%" }}>
          <h1>Danke!</h1>
          <p>Wir haben Ihre Email Adresse bestätigt.</p>
        </div>
      ) : (
        <div style={{ marginLeft: "5%" }}>
          <h1>Es liegt nich an Ihnen, sondern an uns.</h1>
          <p>Leider konnten wir Ihre Email Adresse nicht bestätigen.</p>
        </div>
      )}
    </div>
  );
};
