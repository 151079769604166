import styled from "styled-components";
import { statusMap } from "../shared/models";

export const NewsCard = ({ title, date, href, index, }) => {
  const assets = [
    "dollar-dollar-color.png",
    "euro-dynamic-color.png",
    "pound-dynamic-color.png",
    "rupee-dynamic-color.png",
    "yuan-dynamic-color.png",
  ];

  const styles = {
    backgroundColor: "#F7F7F7",
    height: "13rem",
    width: "19rem",
    borderRadius: "10px",
    marginLeft: "2%",
    position: "relative",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  };

  const titleStyles = {
    fontWeight: "500",
    fontSize: "1rem",
    textAlign: "left",
    marginLeft: "5%",
    marginTop: "40%",
    position: "absolute",
  };

  const imageStyles = {
    width: "30%",
    position: "absolute",
    bottom: "0",
    right: "0",
    borderRadius: "10px",
  };

  const BlurredBackgroundStyle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
  `;

  return (
    <div style={styles}>
      <img src={require(`./assets/${assets[index]}`)} style={imageStyles}></img>
      <BlurredBackgroundStyle></BlurredBackgroundStyle>
      <p style={titleStyles}>{title}</p>
    </div>
  );
};

export const CaseCard = ({ title, status, caseId, showStatus, onClick }) => {
  const styles = {
    backgroundColor: "#32006B",
    height: "13rem",
    width: "19rem",
    borderRadius: "10px",
    marginLeft: "2%",
    position: "relative",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  };

  const statusCardStyles = {
    backgroundColor: "#F2F2F2",
    minWidth: "60%",
    maxWidth: "80%",
    height: "20%",
    borderRadius: "5px",
    marginTop: "2%",
    marginRight: "2%",
    float: "right",
    color: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };

  const titleStyles = {
    color: "white",
    fontSize: "1.4rem",
    fontWeight: "700",
    textAlign: "left",
    marginLeft: "5%",
    position: "absolute",
    marginTop: "30%",
  };

  return (
    <div style={styles} onClick={onClick}>
      {showStatus ? (
        <div style={statusCardStyles}>
          <p>{statusMap[status]}</p>
        </div>
      ) : undefined}
      <p style={titleStyles}>{title}</p>
    </div>
  );
};
