import { Header } from "../shared/header";
import React, { useState } from "react";
import "./vertrag.css";
import { useAuth } from "../Auth/provider";
import axios from "axios";
import { useAnalytics } from "../analytics";
import { getStageConfig } from "../config/config";
import { useEffect } from "react";

export const Contract = () => {
  const config = getStageConfig();
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [fileName, setFileName] = useState("");
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(
    "Ihr Vertrag wird sicher geprüft, bitte warten Sie einen Moment..."
  );
  const [submitted, setSubmitted] = useState(false);

  const { user } = useAuth();

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("file:", file);

    if (file && file.type === "application/pdf") {
      setPdfFile(file);
      setFileName(file.name);
      setLoading(true);
    } else {
      alert("Please upload a valid PDF file.");
      setFileName("");
    }
  };

  useEffect(() => {
    if (pdfFile) {
      onSubmit();
    }
  }, [pdfFile]);

  useAnalytics();

  const onSubmit = async () => {
    console.log("Uploading file...");
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);

    console.log("formData entries:");
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    try {
      const result = await axios.post(
        `${config.API_URL}/default/users/${user.userId}/contracts`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      console.log(`result: ${JSON.stringify(result)}`);

      if (result.status === 200) {
        setLoading(false);
        setAnalysis(result.data.analysis);
      } else {
        setMessage(
          "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
        );
        setLoading(false);
      }
      setPdfUrl(URL.createObjectURL(pdfFile));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  {
    /**<HeroSection
        title1="FRAGWÜRDIGER"
        title2="VERTRAG?"
        text1="Lass uns Ihren Vertrag analysieren, und erfahre"
        text2="was für Klausen rechtswiedrig sind."
        url={require("../Home/assets/hero-reasons.png")}
      ></HeroSection> 
      
      <Explainer
          title1="Wie es funktioniert:"
          instructions={[
            "Sie laden Ihren Vertrag als PDF hoch",
            "Ihr Mietvertrag wird von unserer KI für widerrechtliche Klausen durchsucht",
            "Sie bekommen ein ersten Einblick in die Entdeckte Klausen",
            "Sie können unserem Chat über Ihren Vertrag Fragen stellen.",
          ]}
        />*/
  }

  const statusImages = {
    LEGAL: require("../shared/assets/check.png"),
    ILLEGAL: require("../shared/assets/1904654_cancel_close_cross_delete_reject_icon.png"),
    INVALID: require("../shared/assets/1904654_cancel_close_cross_delete_reject_icon.png"),
  };

  const uploadButtonStyle = {
    backgroundColor: "rgba(105, 173, 255, 0.2)",
    border: "1px solid #868080",
    borderRadius: "20px",
    width: "20vw",
    height: "4.5rem",
    marginTop: "2rem",
    display: "grid",
    gridTemplateColumns: "20% 80%",
    placeItems: "center",
  };

  const submitButtonStyle = {
    width: "23vw",
    height: "3.5rem",
    borderRadius: "100px",
    backgroundColor: "black",
    border: "none",
    color: "white",
    fontSize: "1rem",
    fontWeight: 700,
    cursor: "pointer",
    marginTop: "2rem",
  };

  return (
    <div className="vertrag-app">
      <Header staticColor={true} />
      <div style={{ marginTop: "5rem" }}>
        <div style={{ marginLeft: "5%" }}>
          <h1 style={{}}>Vertrag analysieren</h1>
          <h2 style={{ fontSize: "1rem", color: "lightgray" }}>
            Prüfen Sie Ihren aktuellen Mietvertrag auf ungültige Klauseln.
          </h2>
          {loading ? (
            <div className="loading-container">
              <p>{message}</p>
              <div className="loading-spinner"></div> {/* Custom spinner */}
            </div>
          ) : submitted ? (
            <iframe
              src={pdfUrl}
              className="pdf-viewer"
              title="PDF Viewer"
            ></iframe>
          ) : (
            <div>
              <div style={uploadButtonStyle}>
                <img
                  style={{
                    width: "80%",
                    marginLeft: "30%",
                    alignItems: "center",
                  }}
                  src={require("../shared/assets/camera.png")}
                ></img>
                <label
                  htmlFor="fileUpload"
                  style={{
                    textAlign: "left",
                    color: "#69ADFF",
                    fontWeight: "700",
                  }}
                >
                  Vertrag hochladen
                </label>
                <input
                  id="fileUpload"
                  type="file"
                  onChange={onFileChange}
                  style={{ display: "none" }} // Hide the default file input
                />
              </div>
              {fileName ? (
                <p className="file-name">Ausgewählte Datei: {fileName}</p>
              ) : (
                <p className="file-name">Keine Datei ausgewählt</p>
              )}

              {/**<button onClick={onSubmit} className="submit-button">
                Analysieren
              </button> */}

              {analysis ? (
                <div style={{ marginTop: "20%" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.6rem" }}>
                    Auswertung
                  </p>
                  <div>
                    {true ? (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "10% 90%",
                        }}
                      >
                        <img
                          style={{ width: "30%" }}
                          src={statusImages[analysis.status]}
                        ></img>
                        <p style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                          {analysis.status != "INVALID"
                            ? analysis
                            : "Bitte laden Sie einen Mietvertrag hoch."}
                        </p>
                      </div>
                    ) : undefined}
                  </div>
                  {analysis.status === "ILLEGAL" ? (
                    <button
                      style={{
                        ...submitButtonStyle,
                        backgroundColor: "black",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      Einspruch geltend machen
                    </button>
                  ) : undefined}
                </div>
              ) : undefined}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
