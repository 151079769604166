import { BlurredBackground, SectionHead } from "./shared/components";
import { CaseCard, NewsCard } from "./App/components";
import { Container } from "./shared/components";
import { Account } from "./Account";
import { Header } from "./shared/header";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { CasesMenu } from "./Cases";
import { useAuth } from "./Auth/provider";
import { useAnalytics } from "./analytics";
import { getStageConfig } from "./config/config";
import ReactGA from "react-ga4";

ReactGA.initialize("G-7ZVT49R3P1");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

const App = () => {
  const config = getStageConfig();
  const parentRef = useRef();
  const nestedRef = useRef();

  const [accountVisible, setAccountVisible] = useState(false);
  const [casesVisible, setCasesVisible] = useState(false);
  const [Case, setCase] = useState();
  const [cases, setCases] = useState();
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cases = await axios.get(`${config.API_URL}/cases/${user.userId}`);
        setCases(cases.data);
      } catch (err) {
      } finally {
      }
    };
    fetchData();
  }, []);

  useAnalytics();

  const news = [
    { title: "Titel" },
    { title: "Titel" },
    { title: "Titel" },
    { title: "Titel" },
  ];

  const handleCasesClick = (index) => {
    setCasesVisible(!casesVisible);
    setCase(cases[index]);
  };

  return (
    <div className="App" ref={parentRef}>
      {/*<Navigation></Navigation>*/}
      {accountVisible ? (
        <Account
          onClick={() => setAccountVisible(!accountVisible)}
          account={user}
        ></Account>
      ) : undefined}

      {casesVisible ? (
        <CasesMenu
          Case={Case}
          onClick={() => setCasesVisible(false)}
        ></CasesMenu>
      ) : undefined}
      <Header
        parentRef={parentRef}
        nestedRef={nestedRef}
        staticColor={true}
        onAccountClick={() => setAccountVisible(true)}
      ></Header>
      <SectionHead
        style={{ marginTop: "12%" }}
        label={`Neugikeiten in ${"user.city"}`}
      ></SectionHead>
      <Container
        gap="3rem"
        itemsPerRow={4}
        style={{ marginLeft: "5%", marginTop: "2%" }}
      >
        {news.map((input, index) => (
          <div>
            <NewsCard title={input.title + index} index={index}></NewsCard>
          </div>
        ))}
      </Container>
      <SectionHead label={"Ihre offenen Fälle"}></SectionHead>
      <Container
        gap="3rem"
        itemsPerRow={4}
        style={{ marginLeft: "5%", marginTop: "2%" }}
      >
        {cases
          ? cases.map((input, index) => (
              <div>
                <CaseCard
                  title={input.title}
                  status={input.status}
                  showStatus={true}
                  onClick={() => handleCasesClick(index)}
                ></CaseCard>
              </div>
            ))
          : undefined}
      </Container>
      {accountVisible || casesVisible ? (
        <BlurredBackground></BlurredBackground>
      ) : undefined}
    </div>
  );
};

export default App;
