import { devStageConfig } from "./dev";
import { prodStageConfig } from "./prod";

export const getStageConfig = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return devStageConfig;
    case "prod":
      return prodStageConfig;
  }
};
