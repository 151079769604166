import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAnalytics } from "../../analytics";

const FeatureSpinner = ({ children }) => {
  const [index, setIndex] = useState(0);

  const features = [
    {
      type: "no_electricity",
      title: "KEIN STROM",
      subhead: "50-100% WENIGER MIETE.",
      colorScheme: "light",
    },
    {
      type: "cold_water",
      title: "KALTES WASSER",
      subhead: "50-100% WENIGER MIETE",
      colorScheme: "light",
    },
    {
      type: "rent_increase",
      title: "MIETERHÖHUNG",
      subhead: "UNGÜLTIG IN 30-50% DER FÄLLE.",
      colorScheme: "light",
    },
    {
      type: "unallowed_termination",
      title: "UNERLAUBTE KÜNDIGUNG",
      subhead: "60-80% ANFECHTBAR.",
      colorScheme: "dark",
    },
    {
      type: "forbidden_pets",
      title: "VERBOTENE HAUSTIERE",
      subhead: "GEGEN UNERLAUBTE KLAUSELN VORGEHEN.",
      colorScheme: "light",
    },
    {
      type: "high_service_charges",
      title: "HOHE NEBENKOSTEN",
      subhead: "IM DURCHSCHNITT 50-200€ ZU HOCH BERECHNET.",
      colorScheme: "dark",
    },
    {
      type: "no_heating",
      title: "KEINE HEIZUNG",
      subhead: "BIS ZU 50% WENIGER MIETE.",
      colorScheme: "light",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const leftIndex = (index + features.length - 1) % features.length;
  const centerIndex = index;
  const rightIndex = (index + 1) % features.length;

  const styles = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  };

  const carouselStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: "100%",
  };

  const itemStyle = {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "transform 1s ease, opacity 1s ease",
  };

  const smallItemStyle = {
    ...itemStyle,
    transform: "scale(0.6)",
    opacity: 0.5,
  };

  const largeItemStyle = {
    ...itemStyle,
    transform: "scale(0.8)",
    opacity: 1,
  };

  const customClickHandler = () => {
    window.scrollTo(0, 5500);
  };

  useAnalytics();

  return (
    <div style={carouselStyle}>
      <div style={styles}>
        <FeatureCard
          onClick={() => customClickHandler()}
          style={smallItemStyle}
          key={leftIndex}
          title={features[leftIndex].title}
          subhead={features[leftIndex].subhead}
          type={features[leftIndex].type}
          colorScheme={features[leftIndex].colorScheme}
        ></FeatureCard>
        <FeatureCard
          onClick={() => customClickHandler()}
          style={largeItemStyle}
          key={centerIndex}
          title={features[centerIndex].title}
          subhead={features[centerIndex].subhead}
          type={features[centerIndex].type}
          colorScheme={features[centerIndex].colorScheme}
        ></FeatureCard>
        <FeatureCard
          onClick={() => customClickHandler()}
          style={smallItemStyle}
          key={rightIndex}
          title={features[rightIndex].title}
          subhead={features[rightIndex].subhead}
          type={features[rightIndex].type}
          colorScheme={features[rightIndex].colorScheme}
        ></FeatureCard>
      </div>
    </div>
  );
};

export const Features = () => {
  const featureStyles = {
    position: "relative",
    marginTop: "5%",
    height: "70vh",
  };

  return (
    <div style={featureStyles}>
      <p style={{ textAlign: "center", fontSize: "2rem", fontWeight: 800 }}>
        SIE SIND MIT IHREM PROBLEM NICHT ALLEINE.
      </p>
      <FeatureSpinner></FeatureSpinner>
    </div>
  );
};

const FeatureCard = ({ type, title, subhead, style, colorScheme, onClick }) => {
  const navigate = useNavigate();
  const styles = {
    height: "50vh",
    width: "25vw",
    borderRadius: "1rem",
  };

  const titleColor = {
    light: { color: "white" },
    dark: { color: "black" },
  }[colorScheme];

  const subheadColor = {
    light: { color: "#DFDFDF" },
    dark: { color: "#868080" },
  }[colorScheme];

  console.log(`color: ${titleColor}`);

  const image = {
    no_heating: require("../assets/features/cold-apartment.png"),
    high_service_charges: require("../assets/features/bill.jpg"),
    forbidden_pets: require("../assets/features/pets.jpg"),
    unallowed_termination: require("../assets/features/contract.jpg"),
    rent_increase: require("../assets/features/money.jpg"),
    cold_water: require("../assets/features/water.jpg"),
    no_electricity: require("../assets/features/electricity.jpg"),
  }[type];

  const BlurredBackgroundStyle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 1rem;
    zindex: 10;
  `;

  const buttonStyle = {
    float: "right",
    backgroundColor: "white",
    zIndex: 10,
    border: "none",
    borderRadius: "100px",
    position: "absolute",
    width: "8rem",
    height: "3rem",
    fontSize: "1rem",
    right: "10px",
    bottom: "20px",
  };

  const handleClick = () => {
    navigate("/register");
  };

  return (
    <div style={{ ...styles, ...style, position: "relative" }}>
      <img
        style={{ ...styles, position: "absolute", backgroundSize: "fill" }}
        src={image}
      ></img>
      <BlurredBackgroundStyle></BlurredBackgroundStyle>
      <p
        style={{
          position: "absolute",
          fontWeight: 800,
          fontSize: "2rem",
          textAlign: "left",
          width: "90%",
          marginLeft: "5%",
          top: "3%",
          marginBottom: 0,
          ...titleColor,
        }}
      >
        {title}
      </p>
      <p
        style={{
          position: "absolute",
          fontWeight: 700,
          fontSize: "1.5rem",
          textAlign: "left",
          marginLeft: "5%",
          width: "90%",
          top: "23%",
          ...subheadColor,
        }}
      >
        {subhead}
      </p>
      <button style={buttonStyle} onClick={onClick}>
        Prüfen
        <img
          style={{
            width: "20%",
            marginLeft: "10%",
          }}
          src={require("../../shared/assets/continue-arrow-dark.png")}
        ></img>
      </button>
    </div>
  );
};
