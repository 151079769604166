import React from "react";
import { useNavigate } from "react-router-dom";
export const AuthError = ({ visibility, type }) => {
  const errorMessages = {
    emailMissing: "Bitte geben Sie eine Email ein.",
    passwordMissing: "Bitte geben Sie ein Passwort ein.",
    nameMissing: "Bitte geben Sie Ihren Namen an.",
    cityMissing: "Bitte geben Sie einen Wohnort an.",
    inputMissing: "Bitte geben Sie Email und Passwort ein.",
    invalidEmail: "Bitte geben Sie eine gültige Email ein.",
    weakPassword:
      "Das Passwort muss mindestens acht Zeichen lang sein und mindestens eine Zahl und einen Buchstaben enthalten.",
    denied: "Email oder Passwort falsch.",
    default: "Ein unbekannter Fehler ist aufgetreten.",
  };

  const errorMessage = errorMessages[type] || errorMessages.default;

  if (!visibility) return null;

  return <p style={{ color: "#721c24" }}>{errorMessage}</p>;
};

export const HomeButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/"); // TODO: Redirect to the specified URL
  };
  return (
    <button
      onClick={() => handleClick()}
      style={{
        marginLeft: "5%",
        marginTop: "2%",
        fontSize: "1.7rem",
        fontWeight: 700,
        backgroundColor: "white",
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s",
      }}
    >
      smartmieter
    </button>
  );
};
