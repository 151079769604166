import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";

export const Terms = ({}) => {
  useAnalytics();
  return (
    <div>
      <Header staticColor={true}></Header>
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "3rem",
            fontWeight: 900,
            marginTop: "25%",
          }}
        >
          SMARTMIETER TERMS
        </h1>
        <p style={{ color: "#AAA6A6", width: "50%", marginLeft: "25%" }}>
          Vielen Dank für Ihr Interesse an SMARTMIETER. Bei SMARTMIETER ist es
          unser oberstes Ziel, Ihnen als Kunde klare, verständliche und
          zuverlässige Informationen rund um das Mietrecht anzubieten. Unser
          Service soll Ihnen auf unkomplizierte Weise rechtliche Orientierung
          bieten und Sie in Ihrer individuellen Situation unterstützen. Wir
          legen großen Wert auf Transparenz und Qualität - durch die Kombination
          aus KI-basierter Unterstützung und direktem Zugang zu spezialisierten
          Partner-Anwälten stellen wir sicher, dass Sie immer bestens informiert
          sind.
        </p>
        <AGB></AGB>
      </Container>
      <Footer></Footer>
    </div>
  );
};

const Sectiontitle = ({ children }) => (
  <h3 style={{ fontSize: "1.5em", margin: "1em 0", fontWeight: "bold" }}>
    {children}
  </h3>
);

const AGB = () => (
  <div
    style={{
      width: "50%",
      marginLeft: "25%",
      marginTop: "20%",
      marginBottom: "10%",
    }}
  >
    <Sectiontitle>1. Geltungsbereich und Vertragsgegenstand</Sectiontitle>
    <p>
      Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle
      Geschäftsbeziehungen zwischen der SMARTMIETER UG (im Folgenden
      „SMARTMIETER“ oder „wir“) und ihren Kunden (im Folgenden „Nutzer“ oder
      „Sie“), die unsere Website und angebotenen Dienste nutzen. Durch die
      Nutzung unserer Website und Dienste erklären Sie sich mit diesen AGB
      einverstanden. Der Kaufvertrag kommt durch das Anklicken des Buttons
      "Account erstellen" auf unserer Payment-Seite zustande, wodurch der Nutzer
      diese AGB akzeptiert.
    </p>

    <Sectiontitle>2. Leistungsbeschreibung</Sectiontitle>
    <p>
      SMARTMIETER bietet auf seiner Website KI-gestützte Rechtsberatungen sowie
      individuelle Beratungsleistungen durch Partner-Anwälte an. Unser
      Schwerpunkt liegt auf dem Mietrecht. Wir bieten Einzelfallberatungen
      (einmalige Kosten) und Abonnements (monatlich oder jährlich, entsprechend
      gekennzeichnet) an. SMARTMIETER weist darauf hin, dass die Genauigkeit und
      Vollständigkeit der von der KI generierten Informationen nicht garantiert
      werden können. Verbindliche Rechtsberatung kann nur durch unsere
      Partner-Anwälte geleistet werden.
    </p>

    <Sectiontitle>3. Vertragsschluss und Zahlungsbedingungen</Sectiontitle>
    <p>
      Der Vertrag zwischen SMARTMIETER und dem Nutzer kommt durch das Klicken
      des Buttons „Account erstellen“ auf der Payment-Seite zustande. Alle
      anfallenden Gebühren sind im Voraus zu entrichten. Die Abrechnung erfolgt
      entweder einmalig (für Einzelfallberatungen) oder als Abonnement
      (monatlich oder jährlich) je nach gewähltem Angebot.
    </p>

    <Sectiontitle>4. Nutzung der KI-basierten Beratung</Sectiontitle>
    <p>
      Die auf unserer Website bereitgestellten KI-basierten Informationen und
      Antworten dienen ausschließlich der Orientierung und Information. Sie
      stellen keine rechtlich verbindliche Beratung dar und ersetzen nicht die
      Beratung durch einen qualifizierten Anwalt. SMARTMIETER übernimmt keine
      Haftung für die Richtigkeit oder Vollständigkeit der von der KI
      generierten Antworten. Die Verlässlichkeit der Auskünfte unserer KI kann
      nicht garantiert werden.
    </p>

    <Sectiontitle>5. Haftungsausschluss</Sectiontitle>
    <p>
      (a) SMARTMIETER haftet nicht für Schäden, die durch die Nutzung oder das
      Vertrauen auf Informationen und Empfehlungen unserer KI entstehen. Nutzer
      sind selbst dafür verantwortlich, die von der KI generierten Informationen
      kritisch zu prüfen und gegebenenfalls anwaltliche Beratung in Anspruch zu
      nehmen. <br />
      (b) SMARTMIETER übernimmt keine Verantwortung für die Kommunikation mit
      Dritten, einschließlich Nachrichten an Vermieter oder Hausverwaltungen,
      die aufgrund von KI-Empfehlungen verfasst werden. Der Nutzer ist für die
      Korrektheit und Wirkung solcher Nachrichten selbst verantwortlich. <br />
      (c) Die Haftung für die von unseren Partner-Anwälten erbrachten Leistungen
      liegt ausschließlich bei den jeweiligen Anwälten. SMARTMIETER übernimmt
      hierfür keine Haftung.
    </p>

    <Sectiontitle>6. Verfügbarkeit der Dienste</Sectiontitle>
    <p>
      SMARTMIETER bemüht sich, die Verfügbarkeit der Website und der angebotenen
      Dienste so hoch wie möglich zu halten. Es wird jedoch keine Garantie für
      die ständige Verfügbarkeit gegeben. Ausfälle und Wartungsarbeiten können
      ohne vorherige Ankündigung erfolgen, und SMARTMIETER haftet nicht für
      dadurch entstandene Schäden oder Ausfälle.
    </p>

    <Sectiontitle>
      7. Kündigung und Beendigung des Vertragsverhältnisses
    </Sectiontitle>
    <p>
      Einzelfallberatungen enden mit der einmaligen Leistungserbringung durch
      SMARTMIETER oder den Partner-Anwalt. Abonnementverträge können monatlich
      oder jährlich, abhängig vom gewählten Plan, von beiden Seiten mit einer
      Frist von 14 Tagen zum Ablauf des jeweiligen Abrechnungszeitraums
      gekündigt werden. Kündigungen können schriftlich oder über die Website
      erfolgen.
    </p>

    <Sectiontitle>8. Datenschutz</Sectiontitle>
    <p>
      SMARTMIETER behandelt personenbezogene Daten gemäß den geltenden
      Datenschutzbestimmungen. Nähere Informationen zum Umgang mit
      personenbezogenen Daten finden Sie in unserer{" "}
      <a href="/privacy" style={{ color: "black" }}>
        Datenschutzerklärung
      </a>
      .
    </p>

    <Sectiontitle>9. Änderungen der AGB</Sectiontitle>
    <p>
      SMARTMIETER behält sich das Recht vor, diese AGB jederzeit zu ändern.
      Änderungen werden den Nutzern per E-Mail oder über die Website mitgeteilt.
      Die weitere Nutzung der Dienste nach Inkrafttreten der Änderungen gilt als
      Zustimmung zu den geänderten Bedingungen.
    </p>

    <Sectiontitle>10. Schlussbestimmungen</Sectiontitle>
    <p>
      Sollte eine Bestimmung dieser AGB ganz oder teilweise unwirksam sein oder
      werden, bleibt die Wirksamkeit der übrigen Bestimmungen davon unberührt.
      Anstelle der unwirksamen Bestimmung tritt eine dem wirtschaftlichen Zweck
      der ursprünglichen Bestimmung möglichst nahekommende Regelung.
    </p>

    <p>Stand: 10. Dezember 2024</p>
  </div>
);

export default AGB;
