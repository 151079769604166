import { useState } from "react";
import { Input, SplitPage, Container, SmallInput } from "../shared/components";
import { AuthError, HomeButton } from "../Auth/components";
import {
  BankingCheckout,
  CreditCardCheckout,
  PaymentCard,
  PayPalCheckout,
} from "./components";
import { Button } from "../shared/components";
import { PaymentError } from "./components";
import { useAnalytics } from "../analytics";

const Payment = () => {
  const [paymentMethod, setPaymentMethod] = useState("credit_card");
  const [paymentState, setPaymentState] = useState(false);
  const [paymentError, setPaymentError] = useState("");

  const [cardNumber, setCardNumber] = useState("");
  const [validUntil, setValidUntil] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [cvc, setCVC] = useState("");

  const validateCreditCard = (cardNumber) => {
    var s = 0;
    var doubleDigit = false;
    for (var i = cardNumber.length - 1; i >= 0; i--) {
      var digit = +cardNumber[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    return s % 10 == 0;
  };

  const currentPaymentMethodStyle = {
    color: "black",
    border: "1px solid black",
  };

  const otherPaymentMethodStyle = {
    color: "#AAA6A6",
    border: "1px solid #AAA6A6",
  };

  const choosePaymentMethod = (type) => {
    setPaymentMethod(type);
  };

  useAnalytics();

  const confirmClicked = () => {
    // validate
    // stripe api
    // update accountStatus
    // set postal Code
  };

  return (
    <div>
      <SplitPage color="white" float="left">
        <HomeButton />
        <Container
          style={{
            marginLeft: "10%",
            display: "flex",
            flexDirection: "column",
          }}
          itemsPerRow={1}
          itemWidth="100%"
        >
          <h1 style={{ fontSize: "2rem", fontWeight: 800, marginTop: "20%" }}>
            Zahlung
          </h1>
          <Container
            margin="0"
            itemsPerRow={1}
            itemWidth="33rem"
            style={{
              alignItems: "stretch",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ fontWeight: 600, marginTop: "5%", fontSize: "1.1rem" }}>
              Zahlungsmethode
            </p>
            <Container
              margin="0"
              itemsPerRow={3}
              itemWidth="9.9rem"
              style={{
                width: "33rem",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3%",
              }}
            >
              <PaymentCard
                imageSrc={"./assets/credit_card.png"}
                paymentType={"Kreditkarte"}
                cardStyle={
                  paymentMethod == "credit_card"
                    ? currentPaymentMethodStyle
                    : otherPaymentMethodStyle
                }
                onClick={() => choosePaymentMethod("credit_card")}
              ></PaymentCard>
              <PaymentCard
                imageSrc={"./assets/paypal.png"}
                paymentType={"PayPal"}
                cardStyle={
                  paymentMethod == "paypal"
                    ? currentPaymentMethodStyle
                    : otherPaymentMethodStyle
                }
                onClick={() => choosePaymentMethod("paypal")}
              ></PaymentCard>
              <PaymentCard
                imageSrc={"./assets/banking.png"}
                paymentType={"Banküberweisung"}
                cardStyle={
                  paymentMethod == "banking"
                    ? currentPaymentMethodStyle
                    : otherPaymentMethodStyle
                }
                onClick={() => choosePaymentMethod("banking")}
              ></PaymentCard>
            </Container>

            <div>
              {paymentMethod == "credit_card" ? (
                <CreditCardCheckout></CreditCardCheckout>
              ) : undefined}
              {paymentMethod == "paypal" ? (
                <PayPalCheckout></PayPalCheckout>
              ) : undefined}
              {paymentMethod == "banking" ? (
                <BankingCheckout></BankingCheckout>
              ) : undefined}
            </div>
            <Button
              onClick={() => confirmClicked()}
              label={"Account erstellen"}
              style={{ marginTop: "5%" }}
            ></Button>
            <p
              style={{ color: "#868080", fontSize: "0.5rem", marginTop: "2%" }}
            >
              Durch das Bestellen akzeptieren Sie unsere AGB und willigen ein,
              dass wir Ihr Zahlungsmittel für zukünftige Transaktionen belasten.
            </p>
            <PaymentError
              visibility={paymentState}
              type={paymentError}
            ></PaymentError>
          </Container>
        </Container>
      </SplitPage>
      <SplitPage float="right">
        <Container
          itemsPerRow={1}
          itemWidth="80%"
          style={{ margin: "5rem 0rem 0rem 5rem" }}
        >
          <h3 style={{ color: "white", fontSize: "2rem" }}>
            So bekommen Sie <span style={{ color: "black" }}>Recht </span> in
            vier Schritten.
          </h3>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              1
            </h3>
            <p style={{ justifyContent: "center" }}>
              Erkundigen Sie sich unverbindlich über Ihre Möglichkeiten.
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              2
            </h3>
            <p style={{ justifyContent: "center" }}>
              Erhalten Sie schnell und rund um die Uhr Hilfe bei Ihrem Anliegen.
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              3
            </h3>
            <p style={{ justifyContent: "center" }}>
              Wir verbinden Sie mit einer spezialisierten Kanzlei aus unserem
              Netzwerk.
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              4
            </h3>
            <p style={{ justifyContent: "center" }}>
              Erhalten Sie Recht in Ihrer Sache.
            </p>
          </Container>
        </Container>
      </SplitPage>
    </div>
  );
};
export default Payment;
