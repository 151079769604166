import { useState, useEffect } from "react";
import { CancelButton } from "../shared/components";
import { Container } from "../shared/components";
import { getDateTimeByISO } from "../shared/helpers";
import { statusMap } from "../shared/models";
import { CaseCardMenu } from "./CaseCardMenu";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../analytics";
import { getStageConfig } from "../config/config";

export const CasesMenu = ({ Case, onClick }) => {
  const config = getStageConfig()
  const navigate = useNavigate();
  const [events, setEvents] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/default/events/${Case.caseId}`
        );
        setEvents(response.data);
      } catch (err) {
      } finally {
      }
    };

    fetchData();
  }, []);

  useAnalytics();

  const styles = {
    backgroundColor: "white",
    height: "100%",
    width: "30%",
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: 20,
  };

  const handleAiChatClick = () => {
    navigate("/chat");
  };

  const handleAttorneyChatClick = () => {
    navigate("/chat/attorney");
  };

  return (
    <div style={styles}>
      <Container itemsPerRow={1} style={{ marginLeft: "10%" }}>
        <Container
          itemsPerRow={2}
          columnWidths={["50%", "50%"]}
          style={{ marginTop: "5%" }}
        >
          <p
            style={{
              textAlign: "left",
              fontSize: "1.5rem",
              fontWeight: "600",
            }}
          >
            {Case.title}
          </p>
          <CancelButton onClick={onClick}></CancelButton>
        </Container>

        <Container itemsPerRow={1}>
          <CaseCardMenu
            showStatus={false}
            title={"Chat mit KI-Advisor"}
            onClick={() => handleAiChatClick()}
          ></CaseCardMenu>
          <CaseCardMenu
            showStatus={false}
            title={"Chat mit Anwalt"}
            onClick={() => handleAttorneyChatClick()}
          ></CaseCardMenu>
        </Container>
        <p style={{ fontWeight: 600, fontSize: "1.4rem", marginTop: "1rem" }}>
          Ereignisse
        </p>
        <div>
          {events
            ? events.map((item, index) => (
                <Container
                  itemsPerRow={2}
                  itemWidth="fit-content"
                  style={{ width: "19rem" }}
                  columnWidths={["60%", "40%"]}
                >
                  <p style={{ whiteSpace: "nowrap" }}>
                    {statusMap[item.eventType] ?? ""}
                  </p>
                  <p style={{ fontSize: ".9rem", whiteSpace: "nowrap" }}>
                    {getDateTimeByISO(item.createdAt)}
                  </p>
                </Container>
              ))
            : undefined}
        </div>
      </Container>
    </div>
  );
};
