import { Input, Container, SmallInput } from "../shared/components";

export const PaymentCard = ({ imageSrc, paymentType, onClick, cardStyle }) => {
  const cardStyles = {
    width: "9.9rem",
    height: "4rem",
    borderRadius: ".8rem",
    border: "1px solid #AAA6A6",
    ...cardStyle,
  };

  const imageStyles = {
    width: "16%",
    marginTop: "5%",
    marginLeft: "10%",
  };

  return (
    <div style={cardStyles} onClick={onClick}>
      <img style={imageStyles} src={require(`${imageSrc}`)}></img>
      <p style={{ marginLeft: "10%" }}>{paymentType}</p>
    </div>
  );
};

export const PaymentError = ({ visibility, type }) => {
  const errorMessages = {
    cardFormatInvalid: "Bitte geben Sie eine gültige Kartennummer ein.",
    inputMissing: "Bitte geben Sie Ihre Zahlungsdetails ein.",
    default: "Ein unbekannter Fehler ist aufgetreten.",
  };

  const errorMessage = errorMessages[type] || errorMessages.default;

  if (!visibility) return null;

  return <p style={{ color: "#721c24" }}>{errorMessage}</p>;
};

export const CreditCardCheckout = ({
  handleCardNumberChanged,
  handleValidUntilChanged,
  handleCVCChanged,
  handlePostalCodeChanged,
}) => {
  return (
    <div>
      <p style={{ fontWeight: 600, marginTop: "5%", fontSize: "1.1rem" }}>
        Zahlungsdetails
      </p>
      <Input
        onChange={handleCardNumberChanged}
        placeholder="Kartennummer"
        type="text"
        inputmode="numeric"
        style={{
          border: "1px solid #AAA6A6",
          width: "100%",
          marginTop: "3%",
        }}
      ></Input>
      <Container
        itemsPerRow={2}
        margin="0"
        style={{
          width: "33rem",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3%",
        }}
      >
        <SmallInput
          style={{ border: "1px solid #AAA6A6" }}
          placeholder="Ablaufdatum"
          onChange={handleValidUntilChanged}
        ></SmallInput>
        <SmallInput
          style={{
            float: "right",
            border: "1px solid #AAA6A6",
          }}
          placeholder="CVC"
          onChange={handleCVCChanged}
        ></SmallInput>
      </Container>
      <Input
        onChange={handlePostalCodeChanged}
        placeholder="Postleitzahl"
        type="text"
        style={{ border: "1px solid #AAA6A6", marginTop: "3%" }}
      ></Input>
    </div>
  );
};

export const PayPalCheckout = () => {

}

export const BankingCheckout = () => {
  
}
