import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";

export const AboutUs = ({}) => {
  const styles = {
    backgroundColor: "#69ADFF",
    height: "100vh",
    width: "100%",
    marginBottom: "5%",
    top: 0,
    margin: 0,
    position: "relative",
  };

  useAnalytics();

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header staticColor={true}></Header>
      <div style={styles}>
        <Container
          itemWidth="100%"
          columnWidths={["100%"]}
          style={{ width: "100%", margin: 0 }}
        >
          <h2
            style={{
              marginLeft: "10%",
              fontSize: "2rem",
              fontWeight: 700,
              marginTop: "30%",
              bottom: 0,
              marginBottom: 0,
              color: "#E6E6E6",
            }}
          >
            Über uns
          </h2>
          <h1
            style={{
              position: "absolute",
              marginTop: "0",
              marginLeft: "10%",
              fontSize: "5rem",
              fontWeight: 900,
              marginBottom: 0,
              color: "white",
              zIndex: 10,
            }}
          >
            ALL THINGS RENT
          </h1>
          <h3
            style={{
              position: "absolute",
              marginLeft: "10%",
              fontSize: "2rem",
              fontWeight: 500,
              marginTop: "5%",
              color: "white",
              zIndex: 10,
            }}
          >
            WIR HELFEN BEI ALLEN THEMEN RUND UM DEINE MIETE.
          </h3>
          <img
            style={{
              position: "absolute",
              width: "80%",
              bottom: 0,
              marginBottom: 0,
              marginTop: "auto",
              marginRight: 0,
              right: 0,
            }}
            src={require("../assets/room-image.png")}
          ></img>
        </Container>
      </div>
      <div style={{ height: "150vh" }}>
        <p
          style={{
            fontWeight: 900,
            fontSize: "3rem",
            marginTop: "10%",
            marginLeft: "50%",
            marginBottom: 0,
          }}
        >
          DIE GRÜNDER
        </p>
        <p
          style={{
            color: "#AAA6A6",
            fontWeight: 500,
            fontSize: "1rem",
            marginTop: "0",
            marginLeft: "50%",
            width: "40%",
          }}
        >
          smartmieter wurde 2024 von Friedrich & Max gegründet um sich für die
          Rechte von Mietern einzusetzen. Durch eine diverse Funktionen
          ermöglichen wir es Mietern sich gegen unwirksame Mieterhöhungen, hohe
          Nebenkosten und Schäden an ihrer Wohnung zu wehren.
        </p>

        <Container
          itemWidth="50%"
          itemsPerRow={2}
          style={{ marginTop: "10%", marginLeft: "10%" }}
          columnWidths={["30%", "70%"]}
        >
          <div
            style={{
              width: "25rem",
              height: "25rem",
              backgroundColor: "#69ADFF",
              borderRadius: "1.5rem",
            }}
          ></div>
          <div>
            <p
              style={{
                color: "#AAA6A6",
                fontWeight: 500,
                fontSize: "1rem",
                marginTop: "0",
                marginBottom: 0,
                bottom: 0,
                marginLeft: "10%",
              }}
            >
              Co-Geschäftsführer, Co-Gründer
            </p>
            <p
              style={{
                fontWeight: 800,
                fontSize: "3rem",
                whiteSpace: "nowrap",
                marginLeft: "10%",
                top: 0,
                marginTop: 0,
                botton: 0,
                marginBottom: 0,
              }}
            >
              FRIEDRICH BURK
            </p>
            <p
              style={{
                width: "80%",
                fontWeight: 500,
                fontSize: "1rem",
                textAlign: "left",
                marginLeft: "10%",
                top: 0,
                marginTop: 0,
              }}
            >
              Friedrich ist 23 und hat Data Science an der UC Berkeley studiert.
              Seitdem ist er in Deutschland bei Deloitte als AI/Analytics
              Professional tätig.
            </p>
          </div>
        </Container>

        <Container
          itemWidth="50%"
          itemsPerRow={2}
          style={{ marginTop: "10%" }}
          columnWidths={["60%", "40%"]}
        >
          <div style={{ marginLeft: "70%", width: "100%" }}>
            <p
              style={{
                width: "100%",
                color: "#AAA6A6",
                fontWeight: 500,
                fontSize: "1rem",
                marginTop: "0",
                textAlign: "right",
                marginBottom: 0,
                bottom: 0,
              }}
            >
              Co-Geschäftsführer, Co-Gründer
            </p>
            <p
              style={{
                width: "100%",
                fontWeight: 800,
                fontSize: "3rem",
                whiteSpace: "nowrap",
                textAlign: "right",
                top: 0,
                marginTop: 0,
                bottom: 0,
                marginBottom: 0,
              }}
            >
              MAX SCHNITZLER
            </p>
            <p
              style={{
                width: "100%",
                fontWeight: 500,
                fontSize: "1rem",
                textAlign: "right",
                top: 0,
                marginTop: 0,
              }}
            >
              Max wurde 2002 geboren und begann bereits früh sich für
              Rechtswissenschaften zu interessieren. Mit 16 Jahren begann er
              während der Schule über zwei Semester Rechtswissenschaften zu
              studieren. Nach dieser Zeit programmierte er mit 18 Jahren eine
              App für Organisation in Schulen und arbeitet seit 2022 als
              Informatiker. Seit 2024 arbeitet er zusammen mit Friedrich an
              einer Lösung für Mieter, um sich gegen Unrecht zu wehren.
            </p>
          </div>
          <div
            style={{
              width: "25rem",
              height: "25rem",
              backgroundColor: "#69ADFF",
              borderRadius: "1.5rem",
            }}
          ></div>
        </Container>
      </div>
      <p style={{ marginLeft: "5%", fontWeight: 600 }}>
        smartmieter: Erbachstraße 13, Magstadt 71106
      </p>
      <Footer></Footer>
    </div>
  );
};
