import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";

export const EasyHelp = () => {
  const titleStyles = {
    fontSize: "2.5rem",
    fontWeight: 700,
  };

  useAnalytics();

  return (
    <div>
      <Container
        itemsPerRow={2}
        itemWidth="fit-content"
        columnWidths={["50%", "50%"]}
        style={{
          marginLeft: "5%",
          height: "70vh",
          position: "relative",
        }}
      >
        <div>
          <p style={titleStyles}>
            HILFE BEKOMMEN - <br></br> ZU IHREN BEDINGUNGEN
          </p>
          <p style={{ fontSize: "1.2rem", fontWeight: 500, marginTop: "7%" }}>
            Wir verstehen, dass Sie andere Dinge zu tun haben, als sich mit
            Ihrem Vermieter oder der Hausverwaltung rumzuschlagen. Wir kümmern
            uns für Sie um all Ihre Angelegenheiten, wenn es um Ihre Miete geht.
          </p>
        </div>
        <img
          style={{ marginLeft: "15%", width: "55%", marginTop: "5%" }}
          src={require("../assets/chat.png")}
        ></img>
      </Container>
    </div>
  );
};
