import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import CognitoUserPool from "./configuration.js";
import axios from "axios";
import { getStageConfig } from "../config/config.js";

const AuthContext = createContext();

const config = getStageConfig();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCurrentUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        setUser(null);
      });
  }, []);

  const getUserByEmail = async (email) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/default/user/${email}`
      );
      console.log(`user: ${JSON.stringify(response.data)}`);
      setUser(response.data);
    } catch (err) {
      setUser(null);
    }
  };

  const signIn = async (email, password) => {
    console.log("attempting sign in");
    try {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: CognitoUserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: (result) => {
            console.log("user authenticated");
            setUser(getUserByEmail(email));

            /**
             * const idToken = result.signInUserSession.idToken.jwtToken;
            const accessToken = result.signInUserSession.accessToken.jwtToken;

            console.log("ID Token:", idToken);
            console.log("Access Token:", accessToken);
            */

            resolve({
              type: "",
              authError: false,
              redirect: "/app",
            });
          },
          onFailure: (error) => {
            console.log("login denied");
            setUser(null);
            reject({
              type: "denied",
              authError: true,
            });
          },
        });
      });
    } catch (error) {
      console.log("error occured");
      return {
        error: "",
        authError: true,
      };
    }
  };

  const signOut = async (email) => {
    try {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: CognitoUserPool,
      });

      await cognitoUser.signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const getCurrentUser = () => {
  const user = CognitoUserPool.getCurrentUser();

  if (user) {
    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else if (session.isValid()) {
          resolve(user);
        } else {
          reject("Session is invalid");
        }
      });
    });
  } else {
    return Promise.reject("No user is currently logged in");
  }
};
