import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const halfPageStyle = {
  height: "100vh",
  width: "50vw",
};

export const SplitPage = ({ color = "#69ADFF", float = "right", children }) => {
  return (
    <div style={{ ...halfPageStyle, backgroundColor: color, float }}>
      {children}
    </div>
  );
};

export const SiteHead = ({ label }) => {
  return <h1>{label}</h1>;
};

export const SectionHead = ({ label, style }) => {
  const styles = {
    textAlign: "left",
    marginLeft: "5%",
    marginTop: "5%",
    ...style,
  };

  return <h1 style={{ ...styles }}>{label}</h1>;
};

export const Input = ({
  type = "text",
  style = {},
  placeholder = "",
  inputMode = "text",
  value,
  onChange,
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      inputMode={inputMode}
      style={{
        padding: "10px",
        fontSize: "16px",
        borderRadius: "7px",
        border: "1px solid black",
        outline: "none",
        height: "3rem",
        width: "100%",
        ...style,
      }}
    />
  );
};

export const SmallInput = ({
  type = "text",
  style = {},
  placeholder = "",
  value,
  onChange,
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={{
        padding: "10px",
        fontSize: "16px",
        borderRadius: "7px",
        border: "1px solid black",
        outline: "none",
        height: "3rem",
        width: "90%",
        ...style,
      }}
    />
  );
};

export const Button = ({ style = {}, label = "placeholder", onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        color: "white",
        backgroundColor: "black",
        padding: "10px 20px",
        fontSize: "16px",
        border: "none",
        borderRadius: "7px",
        height: "3rem",
        width: "100%",
        ...style,
      }}
    >
      {label}
    </button>
  );
};

export const Container = ({
  children,
  itemsPerRow = 1,
  itemWidth = "20vw",
  columnWidths = ["20%", "80%"],
  gap = "16px",
  margin = "5px",
  alignItems = "center",
  style = {},
}) => {
  const gridTemplateColumns =
    columnWidths.length === itemsPerRow
      ? columnWidths.join(" ")
      : `repeat(${itemsPerRow}, minmax(0, 1fr))`;

  return (
    <div
      style={{
        alignItems,
        display: "grid",
        gridTemplateColumns,
        gap, // spacing between items
        margin, // outer margin for the container
        width: `calc(${itemsPerRow} * ${itemWidth} + (${itemsPerRow} - 1) * ${gap})`,
        ...style,
      }}
    >
      {React.Children.map(children, (child) => (
        <div style={{ width: itemWidth }}>{child}</div>
      ))}
    </div>
  );
};

export const HyperLink = ({
  href,
  children,
  style = {},
  externalLink = false,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (externalLink) {
      window.location.href = href;
    } else {
      navigate(href);
    }
  };

  return (
    <a
      onClick={() => handleClick()}
      style={{
        color: "black",
        textDecoration: "underline",
        cursor: "pointer",
        ...style,
      }}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export const CancelButton = ({ onClick }) => {
  const buttonStyle = {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px",
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const imageStyle = {
    width: "30px",
    height: "30px",
    objectFit: "cover",
  };

  const handleCancelClick = () => {
    onClick();
  };

  return (
    <button style={buttonStyle} onClick={handleCancelClick}>
      <img src={require("./assets/cancel.png")} style={imageStyle} />
    </button>
  );
};

export const ContinueButton = ({ onClick, style }) => {
  const buttonStyle = {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px",
    cursor: "pointer",
    ...style,
  };

  const imageStyle = {
    width: "20px",
    height: "20px",
    objectFit: "cover",
  };

  const handleContinueClick = () => {
    onClick();
  };

  return (
    <button style={buttonStyle} onClick={handleContinueClick}>
      <img src={require("./assets/continue-arrow.png")} style={imageStyle} />
    </button>
  );
};

export const BlurredBackground = ({}) => {
  const BlurredBackground = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    zindex: 20;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  `;

  return <BlurredBackground></BlurredBackground>;
};
